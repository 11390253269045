/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 0;
  background-color: rgba(69, 150, 155, 0.06);
  position: relative;
  padding: 5px;
  border-radius: 8px;
  box-shadow: none;
  display: flex;
  align-items: center;
}
.selectric-select-pasien .selectric {
  background-color: #fff;
  padding: 7px;
  border: 1px solid #E8E8E8 !important;
}
.selectric-is-invalid .selectric{
  border: 1px solid #DB163C;
  border-bottom-width: 1px;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 30px);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #45969B;
  padding: 5px 10px;
}
.selectric-select-pasien .selectric .label {
  font-weight: 400;
  color: #52575C;
}
.selectric .button {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.selectric .button::before {
  font-family: 'icomoon';
  content: '\e907';
  color: #F36E21;
  font-size: 13px;
}

@media \0screen\,screen\9 {
  .selectric .button {
    color: #DDD;
    text-align: center;
    font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  }
}

.selectric-focus .selectric {
  border-color: #3E3E40;
}

.selectric-hover .selectric {
  border-color: #3E3E40;
}

.selectric-hover .selectric .button {
  color: #000;
}

.selectric-hover .selectric .button:after {
  border-top-color: #888;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: transparent;
  background-color: rgba(69, 150, 155, 0.06);
  margin-top: 0px;
  border-bottom-width: 1px;
}
.selectric-select-pasien.selectric-open .selectric {
  background-color: #fff;
}

.selectric-open .selectric .label{
  color: #45969B;
}
.selectric-select-pasien.selectric-open .selectric .label{
  color: #52575C;
}

.selectric-open .selectric-items {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
       -o-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}


.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
       -o-transform: translateY(-20px);
          transform: translateY(-20px);
  position: absolute;
  top: 100%;
  left: 0;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  margin: 8px 0;
  padding: 15px 20px;
}



.selectric-items:before,
.selectric-items:after {
  display: none;
  content: "";
  position: absolute;
  top: -9px;
  left: 12px;
  width: 0;
  height: 0;
  border-bottom: 6px solid #BBB;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: 3px;
}

.selectric-items:after {
  margin-top: 4px;
  border-bottom-color: #FFF;
}

.selectric-above .selectric-items:before,
.selectric-above .selectric-items:after {
  top: 100%;
  border-bottom-width: 0;
  border-top: 6px solid #BBB;
  margin-top: 0;
}

.selectric-above .selectric-items:after {
  border-top-color: #FFF;
  margin-top: -1px;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items li {
  display: block;
  padding: 15px 0;
  color: #52575C;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  border-bottom: 1px solid #E8E8E8;
}
.selectric-items li:first-child {
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  display: none;
}
.selectric-items li:last-child {
  border-bottom: 0;
}

.selectric-items li:first-child.selected.highlighted {
  background: #fff;
  color: #000077;
  border-radius: 0;
  display: none;
}

.selectric-items li.selected {
  color: #F36E21;
}

.selectric-items li.highlighted {
  color: #F36E21;
}

.selectric-items li:after {
  display: none;
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  right: -10px;
  width: 30px;
  height: 100%;
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEwIj48cGF0aCBkPSJNMCA1YzAtLjIyNC4wNzItLjQxNS4yMTctLjU3MmwxLjA1My0xLjE0NWMuMTQ0LS4xNTcuMzItLjIzNi41MjYtLjIzNi4yMDYgMCAuMzgyLjA3OS41MjYuMjM2bDIuMjc2IDIuNDgzIDUuMDc5LTUuNTNjLjE0NS0uMTU3LjMyLS4yMzYuNTI2LS4yMzYuMjA2IDAgLjM4Mi4wNzkuNTI2LjIzNmwxLjA1MyAxLjE0NWMuMTQ0LjE1Ny4yMTcuMzQ4LjIxNy41NzIgMCAuMjI0LS4wNzIuNDE1LS4yMTcuNTcybC02LjY1OCA3LjIzOWMtLjE0NC4xNTctLjMyLjIzNi0uNTI2LjIzNi0uMjA2IDAtLjM4Mi0uMDc5LS41MjYtLjIzNmwtMy44NTYtNC4xOTJjLS4xNDUtLjE1Ny0uMjE3LS4zNDgtLjIxNy0uNTcyeiIgZmlsbD0iI2JiYiIvPjwvc3ZnPgo=) no-repeat 50% 50%;
  opacity: 0;
  -webkit-transition: .2s;
          transition: .2s;
}

.selectric-items li.selected:after {
  opacity: 1;
  right: 0;
}

.selectric-items li:hover {
  color: #F36E21;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}